import React, { Component } from "react";
import "./App.css";
import MarqueeView from "./views/MarqueeView";
import { Comment } from "./components/marqueue/Marqueue";
import { createConsumer, Channel } from "@rails/actioncable";

export type MarqueeComment = Comment & { uniqId: string };
interface Props {}
interface States {
  marqueeQueue: Array<MarqueeComment>;
  style: MarqueeViewStyle;
}
interface CableMessage {
  action: string;
  [key: string]: any;
}
export interface MarqueeViewStyle {
  backgroundColor: string;
  color: string;
  tickerBackgroundColor: string;
  animationDuration: string;
  fontSize: string;
  textBorderColor: string;
  textBorderWidth: string;
}

class DummyChannel implements Channel {
  unsubscribe() {}
  perform(_action: string, _data: {}) {}
  send(_data: any) {
    return false;
  }
}

class App extends Component<Props, States> {
  cable = createConsumer(`${process.env.REACT_APP_ACTION_CABLE_URL}`);
  channel = new DummyChannel();
  constructor(props: Props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.channel = this.cable.subscriptions.create(
      {
        channel: "ProjectorChannel",
        conference_id: urlParams.get("c"),
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: (message: CableMessage) => {
          if (message.action == "update_marquee_config") {
            this.setState({ style: message.marquee.config });
          } else if (message.action == "comment_queue") {
            this.setState({
              marqueeQueue: message.comments.map((comment: Comment) => ({
                ...comment,
                uniqId: `${comment.id}-${Date.now()}`,
              })),
            });
          }
        },
      }
    );
    this.state = {
      marqueeQueue: [],
      style: {
        backgroundColor: "#000",
        color: "#fff",
        tickerBackgroundColor: "#aaa",
        animationDuration: "5",
        fontSize: "40",
        textBorderColor: "#000",
        textBorderWidth: "1",
      },
    };
  }
  render() {
    console.log(this.state.marqueeQueue);
    return (
      <div
        className="App"
        style={{ backgroundColor: this.state.style.backgroundColor }}
      >
        <MarqueeView
          marqueeQueue={this.state.marqueeQueue}
          channel={this.channel}
          style={this.state.style}
          onMarqueeEnd={(comment) => {
            if (this.state.marqueeQueue.length === 0) {
              return
            }
            if (comment.uniqId === this.state.marqueeQueue[0].uniqId) {
              this.channel.perform("marquee_comment", {
                comment: comment.id,
              });
            }
          }}
        />
      </div>
    );
  }
}

export default App;
