import React from "react";
import { Channel } from "@rails/actioncable";
import { MarqueeComment, MarqueeViewStyle } from "../App";
import Marqueue from "../components/marqueue/Marqueue";

interface Props {
  marqueeQueue: Array<MarqueeComment>;
  channel: Channel;
  style: MarqueeViewStyle;
  onMarqueeEnd: (comment: MarqueeComment) => void;
}
export default function (props: Props) {
  return (
    <Marqueue
      {...props}
      onMarqueeEnd={() => {
        props.onMarqueeEnd(props.marqueeQueue[0]);
      }}
    />
  );
}
