import React, { useRef, useEffect } from "react";
import anime from "animejs";
import { MarqueeViewStyle } from "../../App";

interface Props {
  onMarqueeEnd: () => any;
  children: () => any;
  style: MarqueeViewStyle;
}

export default function Marquee(props: Props) {
  const marqueeNode = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (marqueeNode.current) {
      const currentWidth = marqueeNode.current.getBoundingClientRect().width;
      console.table({
        duration: parseInt(props.style.animationDuration),
        math: Math.floor(
          (((currentWidth * 2) / window.innerWidth) * 100) / 100
        ),
        rate: ((currentWidth * 2) / window.innerWidth) * 100,
        aa:
          1 +
          Math.floor((((currentWidth * 2) / window.innerWidth) * 100)) / 100,
      });
      anime({
        targets: marqueeNode.current,
        left: `-${currentWidth}px`,
        duration:
          parseInt(props.style.animationDuration) *
          1000 *
          (1 +
            Math.floor((((currentWidth * 2) / window.innerWidth) * 100)) / 100),
        easing: "linear",
        complete: () => props.onMarqueeEnd(),
      });
    }
  }, [marqueeNode]);
  const style = {
    // transitionDuration: `${props.style.animationDuration}s`,
    // animationDuration: `${props.style.animationDuration}s`,
    fontSize: `${props.style.fontSize}px`,
    lineHeight: `${parseInt(props.style.fontSize) + 20}px`,
    WebkitTextStroke: `${props.style.textBorderWidth}px ${props.style.textBorderColor}`,
  };
  if (!props.children) {
    return <div />;
  }
  return (
    <div className="marquee anim" style={style} ref={marqueeNode}>
      {props.children()}
    </div>
  );
}
