import React from "react";
import Marquee from "./Marquee";
import { MarqueeComment, MarqueeViewStyle } from "../../App";

interface Participant {
  name: string;
}
export interface Comment {
  id: string;
  body: string;
  participant: Participant;
}

interface Props {
  marqueeQueue: Array<MarqueeComment>;
  style: MarqueeViewStyle;
  onMarqueeEnd: () => any;
}

export default function (props: Props) {
  return (
    <div
      className="marquee-container"
      style={{
        backgroundColor: props.style.tickerBackgroundColor,
        height: `${parseInt(props.style.fontSize) + 20}px`,
        overflow: "hidden",
      }}
    >
      {props.marqueeQueue.map((marquee, index) => {
        if (index == 0) {
          return (
            <Marquee
              onMarqueeEnd={() => props.onMarqueeEnd()}
              style={props.style}
              key={marquee.uniqId}
            >
              {() => (
                <div style={{ color: props.style.color }}>
                  {/* <span>{marquee.participant.name}</span> */}
                  {/* <span>/</span> */}
                  <span>{marquee.body}</span>
                </div>
              )}
            </Marquee>
          );
        }
      })}
    </div>
  );
}
